.container {
  font-family: "Lato", sans-serif;
  color: #45525f;
  background-color: #f4f3e8;
  padding: 0;
  margin: 0;
}

.link {
  text-decoration: underline;
}

.code {
  display: block;
  margin-bottom: 8px;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #45525f;
}

.input-base {
  border-radius: 8px;
  border: 1px solid #bdbaa2;
  background-color: #fcfbf6;
  color: #6a757f;
  padding: 1rem;
  appearance: none;
  font-size: 1rem;
  line-height: normal;
  margin: 0 0 1rem;
  width: 100%;
}

.input-base:focus-visible {
  border-color: #645672;
  outline: 0;
}

.btn-base {
  font-family: "Josefin Sans", sans-serif;
  border-radius: 999rem;
  padding: 1.25rem 2rem;
  display: inline-flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 0.875rem;
  line-height: normal;
  font-weight: bold;
  appearance: none;
  cursor: pointer;
  justify-content: center;
}

.btn-base[type="submit"] {
  background-image: linear-gradient(
    to right,
    rgb(105, 74, 103) 0%,
    rgb(80, 69, 91) 100%
  );
  border: 0;
  color: #fcfbf6;
  width: 100%;
}

.btn-base[type="submit"]:hover {
  background: linear-gradient(to bottom, #785c76 0%, #62586b 100%);
}

.btn-base[type="submit"]:focus-visible {
  outline: 4px solid rgba(131, 92, 128, 0.5);
}

.root {
  padding-bottom: 24px;
}

.video {
  display: none;
}

.hero {
  padding: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0dec7;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  padding: 0 16px;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}

.btn-social-login {
  padding: 1rem 1.5rem;
  border: 1px solid #e0dec7;
  background-color: #fcfbf6;
  justify-content: flex-start;
  color: #45525f;
}

.btn-social-login:hover {
  background-color: #fefefb;
}

.btn-social-login:focus-visible {
  outline: 4px solid rgba(224, 222, 199, 0.75);
}

.btn-link {
  appearance: none;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  color: #45525f;
  text-decoration: underline;
  display: inline;
  background: transparent;
}

.btn-link:focus-visible {
  outline: none;
  text-decoration-thickness: 2px;
}

.login-form {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.otp-form {
  max-width: 350px;
}

.otp-form label {
  font-size: 1.25rem;
  padding: 0.25rem 0;
  margin-bottom: 0.875rem;
  background: linear-gradient(90deg, #b07a40 0%, #645672 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.terms {
  margin-top: 1.25rem;
}

.small-text {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #45525f;
}

.login-signup-switch {
  margin-top: 0.875rem;
}

.small-text a {
  color: #45525f;
}

.small-text a:hover {
  color: #645672;
}

.small-text a:focus-visible {
  outline: 0;
  text-decoration-thickness: 2px;
}

.otp-input {
  display: flex;
  gap: 0.5rem;
}

.otp-input > input {
  text-align: center;
  padding: 0.875rem 0;
}

.btn-otp-confirm:disabled {
  opacity: 0.5;
}

.or-hr {
  border-top: 1px solid #e0dec7;
  margin: 1.75rem 0;
  text-align: center;
  height: 0px;
}

.or-hr span {
  display: inline-block;
  background-color: #f4f3e8;
  transform: translateY(-100%);
  padding: 0 1rem;
  z-index: 1;
  position: relative;
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
}

@keyframes slide-in {
  from {
    transform: translateX(200%);
  }
}

.logo-sidebar {
  display: none;
}

@media (min-width: 768px) {
  .root {
    padding-bottom: 0;
  }

  .content-wrapper {
    padding: 0 32px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
  }

  .hero {
    display: block;
    position: relative;
    background: #645672;
    margin-bottom: 0;
    padding: 0;
  }

  .logo-header {
    display: none;
  }

  .logo-sidebar {
    display: block;
    margin: 0 auto 1.5rem;
  }

  .video {
    display: block;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
